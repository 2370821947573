import React from "react"
import PropTypes from 'prop-types';
import "./auto-video-player.scss"

class AutoVideoPlayer extends React.Component {

  componentDidMount() {
    this.watchVideo()
  }

  watchVideo() {
    const vid = this.getVideo();
    vid.addEventListener('playing', this.onVideoPlaying)
    setInterval(this.getCurrentTime.bind(this), 250);
  }

  getCurrentTime(e) {
    const vid = this.getVideo();
    let currentTime = vid.currentTime;
    let duration = vid.duration;
    let timeRemaining = duration - currentTime;
    // console.log(`AUTOVIDEOPLAYER  ${this.props.id}: ${currentTime} / ${duration} : ${timeRemaining}`)
    if( timeRemaining <= 1 ) {
      vid.currentTime = 0;
    }
  }

  getVideo() {
    const video = document.getElementById(this.props.id)
    return video;
  }

  static propTypes = {
    id: PropTypes.string,
    classes: PropTypes.string,
    srcXL: PropTypes.string,
    srcL: PropTypes.string,
    srcM: PropTypes.string,
    srcS: PropTypes.string,
    backupXL: PropTypes.string,
    backupL: PropTypes.string,
    backupM: PropTypes.string,
    backupS: PropTypes.string,
  }

  render() {


    return (

      <div className={`video auto-video-player ${this.props.classes}`}>

        {/*<video id={this.props.id} autoPlay loop muted playsinline>*/}
        <video id={this.props.id} autoPlay  muted playsInline>
          <source
            src={this.props.srcL}
            type="video/mp4"/>
        </video>
      </div>

    )
  }
}

export default AutoVideoPlayer