import React from "react"
import ViewportFullsize from "../ViewportFullbleed";
import ContentNoGutter from "../ContentNoGutter";
import "./header.scss"
import {Button} from "@thumbtack/thumbprint-react";
import { gsap, ScrollToPlugin } from "gsap/all";


class Header extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      scrolled: false,
      prepareStickNav: false,
    }
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", this.onScroll.bind(this));
    this.onScroll();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onButtonClick(e) {
    gsap.registerPlugin(ScrollToPlugin);
    gsap.to(window, 1, {scrollTo: "#careers"})
  }

  onScroll() {
    if (this._isMounted) {
      // Get coordinates of two elements. Prepare trigger on H1 prepares the nav by pulling it above
      // the page to allow it to scroll down versus up when triggered to stick
      const prepareEl = document.getElementById(`sticky-header-prepare-trigger`)
      let prepareOffset = this.getOffset(prepareEl).top + prepareEl.offsetHeight;
      const triggerEl = document.getElementById(`sticky-header-trigger`)
      let triggerOffset = this.getOffset(triggerEl).top + triggerEl.offsetHeight;
      // console.log(`HEADER onScroll window.scrollY: ${window.scrollY}, offsetPrepare: ${offsetPrepare}, offset: ${offset}`)
      this.setState( {
        prepareStickNav: window.scrollY > prepareOffset ? true : false,
        scrolled: window.scrollY > triggerOffset ? true : false,
      })
    }
  }

  getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }

  render() {

    return (
      <header>
        <ViewportFullsize>
          <div
            className={`header easeInOutAll50  ${(this.state.prepareStickNav) ? 'prepare-sticky-nav' : ''} ${(this.state.scrolled) ? 'sticky-nav' : ''}`}
            style={{
              margin: `0 auto`,
            }}
          >
            <ContentNoGutter>
              <img
                className={`tack`}
                width="64"
                src="https://ttc-design.s3-us-west-1.amazonaws.com/i/logomark_blue_large_2x.png"
                alt=""
              />

              <Button
                onClick={this.onButtonClick.bind(this)}
              >
                Join us
              </Button>
            </ContentNoGutter>
          </div>
        </ViewportFullsize>
      </header>
    )
  }
}

export default Header