/**
 * Index component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

const ViewportFullbleed = ({ children }) => {
  return (
    <>
      <div
        className="viewport-fullbleed"
        style={{
          margin: `0 auto`,
        }}
      >
        {children}
      </div>
    </>
  )
}


ViewportFullbleed.propTypes = {
  classes: PropTypes.array,
}

ViewportFullbleed.defaultProps = {
  classes: [],
}

export default ViewportFullbleed
