import React from "react"
import PropTypes from "prop-types"
// import Content from "../Content";
import "./footer.scss"
import ViewportFullbleed from "../ViewportFullbleed"
// import ContentNoGutter from "../ContentNoGutter"
import Content from "../Content"
import AutoVideoPlayer from "../AutoVideoPlayer";


const Footer = props => (
  <footer style={{ background: `#FFFFFF` }}
  >
    <ViewportFullbleed>
      <div className="footer">
        <AutoVideoPlayer
          srcL={`https://ttc-design.s3-us-west-1.amazonaws.com/v/TTC20112-Find-Your-Pros-(combined)-07_3x1_1600.mp4`}
          srcM={`https://ttc-design.s3-us-west-1.amazonaws.com/v/TTC20112-Find-Your-Pros-(combined)-07_3x1_946.mp4`}
          srcS={`https://ttc-design.s3-us-west-1.amazonaws.com/v/TTC20112-Find-Your-Pros-(combined)-07_3x1_376.mp4`}
          backupL={`https://ttc-design.s3-us-west-1.amazonaws.com/i/TTC20121-Find-Your-Pros-1_08_1x1_fallback_650.png`}
          backupM={`https://ttc-design.s3-us-west-1.amazonaws.com/i/TTC20121-Find-Your-Pros-1_08_1x1_fallback_650.png`}
          backupS={`https://ttc-design.s3-us-west-1.amazonaws.com/i/TTC20121-Find-Your-Pros-1_08_1x1_fallback_650.png`}
          classes={``}
          id={`footer-video`}
        />

        <Content>

          <div className="grid">
            <div className="col-12">
              <div className="icons">
                <a className={`social`} target="_blank" href="https://dribbble.com/thumbtack">
                  <img
                    className={`icon`}
                    src="https://ttc-design.s3-us-west-1.amazonaws.com/i/icon_dribbble_gray_large_2x.png"
                    alt=""
                  />
                </a>
                <a className={`social`} target="_blank" href="https://medium.com/thumbtack-design">
                  <img
                    className={`icon`}
                    src="https://ttc-design.s3-us-west-1.amazonaws.com/i/icon_medium_gray_large_2x.png"
                    alt=""
                  />
                </a>
                <a className={`social`} target="_blank" href="https://twitter.com/thumbtackdesign">
                  <img
                    className={`icon last`}
                    src="https://ttc-design.s3-us-west-1.amazonaws.com/i/icon_twitter_gray_large_2x.png"
                    alt=""
                  />
                </a>
              </div>
              <div className="col-12 copyright">
                <p className={`copyright`}>
                  © {new Date().getFullYear()} Thumbtack, Inc.
                </p>
              </div>
            </div>
          </div>

        </Content>

      </div>
    </ViewportFullbleed>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
