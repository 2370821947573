/**
 * Index component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./content-no-gutter.scss"

const ContentNoGutter = ({ children }) => {
  return (
    <>
      <div
        className="content-no-gutter"
      >
        {children}
      </div>
    </>
  )
}


ContentNoGutter.propTypes = {
  classes: PropTypes.array,
}

ContentNoGutter.defaultProps = {
  classes: [],
}

export default ContentNoGutter
