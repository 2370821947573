/**
 * Index component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./content.scss"

const Content = ({ children }) => {
  return (
    <>
      <div
        className="content"
      >
        {children}
      </div>
    </>
  )
}

Content.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Content
